import React, { Fragment, useEffect, useState } from 'react'
import { compose } from 'recompose'
import { Location } from '@reach/router'
import { Link } from 'gatsby'
import queryString from 'query-string'
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../components/Session'
import Layout from '../components/layout'
import Dashboard from '../components/Dashboard/Dashboard'
import { PageHeaderMargin, PageHeader } from '../styles/globalStyles'
import { DashStyleContainer } from '../styles/dashStyles'
import { cleanUrl, capitalizeFirst } from '../utils/index'

const DashApp = props => {
  const [urlString, setUrlString] = useState(null)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    setUrlString(urlParams.get('url'))
    return () => {
      console.log('kill')
    }
  }, [])
  return (
    <>
      <AuthUserContext.Consumer>
        {authUser => (
          <DashStyleContainer>
            <PageHeaderMargin />
            <PageHeader align='flex-end'>
              <a href='/home' className='crumb'>
                sites /
              </a>
              <h1>{capitalizeFirst(cleanUrl(urlString))}</h1>
            </PageHeader>
            {urlString && <Dashboard url={urlString} />}
          </DashStyleContainer>
        )}
      </AuthUserContext.Consumer>
    </>
  )
}

const condition = authUser => !!authUser

const DashContainer = compose(
  withEmailVerification,
  withAuthorization(condition)
)(DashApp)

export default () => (
  <Layout>
    <DashContainer />
  </Layout>
)
