import styled from 'styled-components'
import { colors } from '../../styles/vars'

const DashboardContainer = styled.section`
  /* margin-left: -8vw;
  margin-right: -8vw; */
  h2 {
    font-size: 0.6rem;
    color: ${colors.lightBlue};
    margin-left: -8vw;
    margin-right: -8vw;
    padding: 0rem 1rem;
    text-transform: uppercase;
    letter-spacing: 0.04rem;
    opacity: 0.75;
  }
  .box-container {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1.3rem;
    /* border: 2px solid ${colors.backgroundBlue}; */
    /* background-color: ${colors.blueOpac(0.1)}; */
  }
  .box {
    margin-right: 0.3rem;
    display: inline-block;
    /* background-color: ${colors.backgroundBlue}; */
    /* background-color: ${colors.blueOpac(0.06)}; */
    /* border-right: 3px solid ${colors.lightBlue}; */
    padding: 1rem 1.6rem;
    position: relative;
    /* background-color: #fff; */
    svg {
      transform: scale(0.8);
    }
    font-size: 2.2rem;
    color: ${colors.lightBlue};
    /* border: 2px solid ${colors.lightBlue}; */
    font-weight: bold;
    span {
      margin-left: 2rem;
      display: block;
      font-size: 0.6rem;
      opacity: 0.75;
    }
    &:first-child {
      padding-left: 0;
    }
    h4 {
      margin: 0;
      margin-bottom: 0.6rem;
      font-size: 0.9rem;
      color: ${colors.titleBlack};
    }
  }
`
const H2 = styled.h2`
  /* margin-left: -8vw;
  margin-right: -8vw; */
  /* font-size: 1rem; */
  margin-top: 0;
  margin-bottom: 6rem;
  font-size: 2.5rem;
  transform: translateY(120%);
  padding: 0 3rem;
  opacity: 1;
  /* text-transform: uppercase; */
  color: ${({ textColor = 'lightBlue' }) => colors[textColor]};
`

const ScreenShotContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  width: 90vw;
  margin: auto;
  box-sizing: border-box;
`
const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 200px;
  height: 250px;
  box-sizing: border-box;
  margin: 2rem;
  border: 1px solid ${colors.lightBlue};
`
const ImageContainer = styled.div`
  background-position: center center;
  box-sizing: border-box;
  width: 100%;
  height: 80%;
  img {
    width: 90%;
    height: 100%;
    padding: 0 5%;
  }
`
const DataContainer = styled.div`
  box-sizing: border-box;
  width: auto;
  height: 20%;
  padding: 1rem;
  text-align: center;
  color: ${({ textColor = 'lightBlue' }) => colors[textColor]};
`

export {
  DashboardContainer,
  H2,
  ScreenShotContainer,
  DataContainer,
  Container,
  ImageContainer,
}
