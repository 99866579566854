import styled from 'styled-components'
import { colors } from '../styles/vars'

const DashStyleContainer = styled.div`
  .crumb {
    color: ${colors.red};
    border-bottom: 0;
    text-decoration: none;
    font-weight: bold;
    margin-bottom: 0.3rem;
    margin-right: 0.3rem;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease;
    &:hover {
      border-bottom: 2px solid ${colors.red};
    }
  }
`

export { DashStyleContainer }
