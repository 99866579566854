import styled from 'styled-components'
import { colors } from '../../styles/vars'

const TimeChartContainer = styled.div`
  height: 30vh;
  min-height: 200px;
  width: 100vw;
  padding: 0vw;
  padding-top: 0vw;
  box-sizing: border-box;
  background-color: transparent;
  margin-left: -8vw;
  margin-right: -8vw;
  margin-top: 0rem;
  z-index: 2;
`

const TimeHint = styled.div`
  background-color: pink;
  background-color: ${({ bgColor = colors.lightBlue }) => bgColor};
  color: #fff;
  font-size: 0.75rem;
  padding: 0.3rem;
  font-weight: bold;
  position: relative;
  transform: translateY(-0.7rem);
  /* &::after {
    transform: scaleX(-1) translateY(10%);
    position: absolute;
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 1rem;
    right: auto;
    box-sizing: border-box;
    border: 0.3rem solid;
    border-color: ${colors.lightBlue} ${colors.lightBlue} transparent
      transparent;
  } */
`

export { TimeChartContainer, TimeHint }
