import React, { useState, useEffect, Fragment } from 'react'
import axios from 'axios'
import TimeChart from '../TimeChart/TimeChart'
import {
  DashboardContainer,
  H2,
  ScreenShotContainer,
  Container,
  ImageContainer,
  DataContainer,
} from './DashboardStyles'
import { getUpTime, getMeanValue } from '../../utils/responseCalculator'
import { ThumbIcon } from '../../styles/icons'

const URL = 'https://services.sitepillow.com'
const twoDays = 6 * 25 * 1
const lastSeventhDay = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
const today = new Date(Date.now())

const Dashboard = ({ url }) => {
  const [data, setData] = useState([])
  const [durationData, setDurationData] = useState([])
  const [firstByteData, setFirstByteData] = useState([])
  const [downloadData, setDownloadData] = useState([])
  const [upTime, setUpTime] = useState(0)
  const [meanDuration, setMeanDuration] = useState(0)
  const [meanResponse, setMeanResponse] = useState(0)

  //for new screenshot and measurement service
  const [dataS, setDataS] = useState([])
  // const [screenshotTakeTime, setScreenshotTakeTime] = useState([])
  const [domContentLoadedEventEnd, setDomContentLoadedEventEnd] = useState(
    []
  )
  const [domInteractive, setDomInteractive] = useState([])
  const [loadEventEnd, setLoadEventEnd] = useState([])
  // const [responseEnd, setResponseEnd] = useState([])

  useEffect(() => {
    console.log(url)

    axios
      .get(
        `https://services.sitepillow.com/data/${encodeURIComponent(
          url.replace('https://', '')
        )}`
      )
      .then(({ data }) => {
        const timeSpan = data.map((val) => {
          if (
            val.domInteractive === null ||
            val.domContentLoadedEventEnd === null
          ) {
            return {
              ...val,
              domInteractive: 0.0001,
              domContentLoadedEventEnd: 0.0001,
            }
          }
          return val
        })
        const domInt = timeSpan.map(({ time, domInteractive }) => ({
          x: new Date(time),
          y: domInteractive,
        }))
        const domLoaded = timeSpan.map(
          ({ time, domContentLoadedEventEnd }) => ({
            x: new Date(time),
            y: domContentLoadedEventEnd,
          })
        )
        setDomContentLoadedEventEnd(domLoaded)
        setDomInteractive(domInt)
        setDataS(timeSpan)
      })
  }, [])

  useEffect(() => {
    const resp = axios
      .get(
        `https://services.sitepillow.com/url/${encodeURIComponent(url)}`
      )
      .then(({ data }) => {
        setData(data)
        setUpTime(getUpTime(data))
        //  Duration
        const duration = data.map(({ time, duration, firstByte }) => ({
          x: new Date(time),
          y: duration - firstByte,
        }))
        const durationStripped = [...duration].slice(
          Math.max(duration.length - twoDays, 1)
        )
        setDurationData(durationStripped)
        //  First byte
        const firstByte = data.map(({ time, firstByte }) => ({
          x: new Date(time),
          y: firstByte,
        }))
        const firstByteStripped = [...firstByte].slice(
          Math.max(firstByte.length - twoDays, 1)
        )
        setFirstByteData(firstByteStripped)
        //  Means
        setMeanDuration(getMeanValue(data, 'duration', 7))
        setMeanResponse(getMeanValue(data, 'firstByte', 7))
        //  Download
        // const download = data.map(({ time, download }) => ({
        //   x: new Date(time),
        //   y: download,
        // }))
        // const downloadStripped = [...download].slice(
        //   Math.max(download.length - twoDays, 1)
        // )
        // setDownloadData(downloadStripped)
      })
  }, [])

  return (
    <Fragment>
      <DashboardContainer>
        <div className='box-container'>
          <div className='box'>
            <ThumbIcon height='0.65em' width='0.65em' />
            <u>100%</u>
            <span>
              uptime <br />
              last 30 days
            </span>
          </div>
          <div className='box'>
            <ThumbIcon height='0.65em' width='0.65em' />
            <u>0</u>
            <span>
              incidents <br />
              last 30 days
            </span>
          </div>
          <div className='box'>
            <ThumbIcon height='0.65em' width='0.65em' />
            <u>{Math.floor(meanDuration / 10) / 100}s</u>
            <span>
              avg response time <br />
              last 7 days
            </span>
          </div>
          <div className='box'>
            <ThumbIcon height='0.65em' width='0.65em' />
            <u>{Math.floor(meanResponse / 10) / 100}s</u>
            <span>
              avg load time <br />
              last 7 days
            </span>
          </div>
        </div>
        <h2>Past 48 hours</h2>
        <TimeChart data={[firstByteData, durationData]} />
        <br />
        <h2>Past month</h2>
        <TimeChart data={[domInteractive, domContentLoadedEventEnd]} />
        {/* <TimeChart data={firstByteData} colorTheme='blue' /> */}
      </DashboardContainer>
      {/* screenshots data */}
      {/* <H2>Screenshots</H2>
      <ScreenShotContainer>
        {dataS.map((data, i) => (
          <Container key={i}>
            <ImageContainer>
              <a href={data.publicLink} target='_blank'>
                <img src={data.publicLink} alt='Image not found' />
              </a>
            </ImageContainer>
            <DataContainer>{data.time}</DataContainer>
          </Container>
        ))}
      </ScreenShotContainer> */}
    </Fragment>
  )
}

export default Dashboard
