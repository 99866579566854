import React, { useEffect, useState } from 'react'
import '../../../node_modules/react-vis/dist/style.css'
import {
  FlexibleXYPlot,
  LineMarkSeries,
  GradientDefs,
  AreaSeries,
  XAxis,
  YAxis,
  HorizontalGridLines,
  Hint,
} from 'react-vis'
import { maxBy } from 'lodash'
import { colors } from '../../styles/vars'
import get from 'lodash/get'
import { TimeChartContainer, TimeHint } from './TimeChartStyles'

const getColorGradientId = color => 'CoolGradient-' + color

const TimeChart = ({
  data = [],
  colorTheme = 'lightBlue',
  title = '',
}) => {
  // useEffect(() => {
  // }, [rawData])
  // console.log('dataset', dataset)

  const [hoveredValue, setHoveredValue] = useState(null)
  return (
    get(data, '[0][0].y', false) &&
    get(data, '[1][0].y', false) && (
      <TimeChartContainer style={{}}>
        <FlexibleXYPlot
          style={{ width: '100%' }}
          title='Test'
          xType='time'
          yDomain={[
            0,
            maxBy(data[0], o => o.y).y + maxBy(data[1], o => o.y).y,
          ]}
          margin={{
            left: 0,
            right: 0,
          }}
          onMouseLeave={() => setHoveredValue(null)}
          stackBy='y'
        >
          <HorizontalGridLines
            animation
            tickTotal={4}
            style={{
              stroke: colors[colorTheme],
              strokeWidth: 1,
              opacity: 0.15,
            }}
          />
          <XAxis
            style={{
              line: { stroke: colors[colorTheme], opacity: 0.2 },
              ticks: { stroke: colors[colorTheme], opacity: 1 },
              text: {
                stroke: 'none',
                fill: colors[colorTheme],
                fontWeight: 600,
                opacity: 0.7,
                fontSize: '0.6rem',
              },
            }}
          />
          <YAxis
            title={title}
            style={{
              line: { stroke: colors[colorTheme], opacity: 0.2 },
              ticks: { stroke: colors[colorTheme], opacity: 1 },
              title: {
                stroke: 'none',
                fill: colors[colorTheme],
                fontWeight: 200,
                letterSpacing: '1px',
                opacity: 0.9,
                fontSize: '0.70rem',
              },
            }}
          />
          <GradientDefs>
            <linearGradient
              id='CoolGradient-blue'
              x1='0'
              x2='0'
              y1='0'
              y2='1'
            >
              <stop
                offset='0%'
                stopColor={colors.blue}
                stopOpacity={0.9}
              />
              <stop
                offset='100%'
                stopColor={colors.blue}
                stopOpacity={0.12}
              />
            </linearGradient>
            <linearGradient
              id={getColorGradientId(colorTheme)}
              x1='0'
              x2='0'
              y1='0'
              y2='1'
            >
              <stop
                offset='0%'
                stopColor={colors[colorTheme]}
                stopOpacity={0.6}
              />
              <stop
                offset='100%'
                stopColor={colors[colorTheme]}
                stopOpacity={0.12}
              />
            </linearGradient>
            <radialGradient
              id='CoolGradientRad'
              x1='0'
              x2='0'
              y1='0'
              y2='1'
            >
              <stop
                offset='0%'
                stopColor={colors[colorTheme]}
                stopOpacity={1}
              />
              <stop
                offset='23%'
                stopColor={colors[colorTheme]}
                stopOpacity={1}
              />
              <stop
                offset='100%'
                stopColor={colors[colorTheme]}
                stopOpacity={1}
              />
            </radialGradient>
          </GradientDefs>
          {data.map((dataset, index) => (
            <AreaSeries
              data={dataset}
              key={index + 'area'}
              color={`url(#${
                index === 0
                  ? getColorGradientId(colorTheme)
                  : getColorGradientId('blue')
              })`}
              curve={'curveBasis'}
              lineStyle={{
                strokeWidth: 2,
                opacity: 1,
                stroke: colors[colorTheme],
              }}
            />
          ))}

          {data.map((dataset, index) => (
            <LineMarkSeries
              data={dataset}
              key={index + 'line'}
              size={1}
              markStyle={{
                strokeWidth: 1,
                stroke: index === 1 ? colors.blue : colors[colorTheme],
                fill: index === 1 ? colors.blue : colors[colorTheme],
                opacity: 1,
              }}
              lineStyle={{
                strokeWidth: 2,
                opacity: 0.3,
                stroke: index === 1 ? colors.blue : colors[colorTheme],
              }}
              curve={'Linear'}
              onNearestX={(datapoint, event) => {
                setHoveredValue(datapoint)
              }}
            />
          ))}
          {hoveredValue && (
            <Hint value={hoveredValue}>
              <TimeHint bgColor={colors[colorTheme]}>
                {hoveredValue.y} ms
              </TimeHint>
            </Hint>
          )}
        </FlexibleXYPlot>
      </TimeChartContainer>
    )
  )
}

export default TimeChart
