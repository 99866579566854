const statusCodeType = code => {
  switch (code) {
    case code < 200:
      return 'information'
    case code < 300:
      return 'successful'
    case code < 400:
      return 'redirect'
    case code < 500:
      return 'client error'
    default:
      'server error'
  }
}

const isErrorCode = code => {
  const type = statusCodeType(code)
  return type === 'client error' || type === 'server error'
}

export { isErrorCode, statusCodeType }
