import React from 'react'

const ThumbIcon = props => (
  <svg
    focusable='false'
    width='1em'
    height='1em'
    style={{ marginRight: '0.6rem', transform: 'rotate(360deg)' }}
    preserveAspectRatio='xMidYMid meet'
    viewBox='0 0 24 24'
    {...props}
  >
    <g
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3'></path>
    </g>
  </svg>
)

export { ThumbIcon }
