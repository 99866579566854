import { isErrorCode } from './statusCodes'

const oneDay = 6 * 24

/**
 * Up is when site is sending a 200 code response
 */
const getUpTime = data => {
  const down = data.filter(({ code = '200' }) => isErrorCode(code))
  return 1 - down.length / data.length
}

const getMeanValue = (data, variable, days = 7) => {
  if (!data || data.length < 1) {
    return 0
  }
  const stripToDays = [...data].slice(
    Math.max(data.length - oneDay * days, 1)
  )
  const sum = stripToDays.reduce((sum, a) => {
    return sum + a[variable]
  }, 0)
  return sum / stripToDays.length
}
export { getUpTime, getMeanValue }
